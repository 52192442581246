<template>
  <div data-app>
    <b-alert show variant="light" class="alert alert-elevate">
      <div class="alert-icon">
        <i class="flaticon-warning kt-font-brand"></i>
      </div>
      <div class="alert-text">
        {{ $t("UPLOADED_ASSIGNMENT.GUIDE") }}
      </div>
    </b-alert>
    <KTPortlet :title="title">
      <template v-slot:body>
        <b-form class="kt-form" @submit.stop.prevent="onSubmit">
          <div
            role="alert"
            v-if="errors.length"
            v-bind:class="{ show: errors.length }"
            class="alert fade alert-danger"
          >
            <div class="alert-text">
              <ul v-for="(error, i) in errors" :key="i">
                <li>{{ error }}</li>
              </ul>
            </div>
          </div>
          <b-overlay :show="uploading" variant="transparent" rounded="sm">
            <template v-slot:overlay>
              <div class="text-center p-4 bg-info text-light rounded">
                <i class="flaticon-upload" style="font-size: 3rem;"></i>
                <div class="mb-3">
                  {{ $t("CLASS_MATERIALS_UPLOAD.PROCESSING") }}
                </div>
                <b-progress
                  :value="upload_percentage"
                  max="100"
                  variant="success"
                  height="3px"
                  class="mx-n4 rounded-0"
                ></b-progress>
              </div>
            </template>
            <b-row>
              <b-col md="4">
                <div role="group">
                  <label
                    >{{ $t("UPLOADED_ASSIGNMENT.ASSIGNMENT_TITLE") }}:</label
                  >
                  <b-form-input
                    id="assignment_title"
                    name="assignment_title"
                    type="text"
                    v-model="$v.form.assignment_title.$model"
                    :state="validateState('assignment_title')"
                  ></b-form-input>
                  <b-form-invalid-feedback id="input-2-live-feedback">
                    {{
                      $t("VALIDATION.REQUIRED", {
                        name: $t("UPLOADED_ASSIGNMENT.ASSIGNMENT_TITLE")
                      })
                    }}
                  </b-form-invalid-feedback>
                </div>
              </b-col>
              <b-col md="4">
                <div role="group">
                  <label>{{ $t("GENERAL.SELECT_FILE") }}:</label>
                  <b-form-file
                    id="file"
                    name="file"
                    ref="file"
                    v-model="$v.form.file.$model"
                    accept="application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,
                      application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation,
                      application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,
                      application/pdf,video/mp4,image/jpeg,image/png,image/gif"
                    :state="validateState('file')"
                    :placeholder="$t('CLASS_MATERIALS_UPLOAD.SELECT_A_FILE')"
                    :drop-placeholder="$t('CLASS_MATERIALS_UPLOAD.DROP_FILE')"
                  ></b-form-file>
                  <b-form-invalid-feedback id="input-2-live-feedback">
                    {{
                      $t("VALIDATION.REQUIRED", {
                        name: $t("GENERAL.SELECT_FILE")
                      }) +
                        ". " +
                        $t("VALIDATION.MEDIA_TYPE")
                    }}
                  </b-form-invalid-feedback>
                </div>
              </b-col>
              <b-col md="2">
                <label>{{ $t("ASSIGNMENT.DUE_DATE") }}:</label>
                <b-form-input
                  id="due_date"
                  name="due_date"
                  type="date"
                  data-date-format="MM-DD-YYYY"
                  v-model="$v.form.due_date.$model"
                  :state="validateState('due_date')"
                >
                </b-form-input>
                <b-form-invalid-feedback id="input-2-live-feedback">
                  {{
                    $t("VALIDATION.REQUIRED", {
                      name: $t("ASSIGNMENT.DUE_DATE")
                    })
                  }}
                </b-form-invalid-feedback>
              </b-col>
              <b-col md="2">
                <label>{{ $t("ASSIGNMENT.DUE_TIME") }}:</label>
                <b-form-input
                  id="due_time"
                  name="due_time"
                  type="time"
                  v-model="$v.form.due_time.$model"
                  :state="validateState('due_time')"
                >
                </b-form-input>
                <b-form-invalid-feedback id="input-2-live-feedback">
                  {{
                    $t("VALIDATION.REQUIRED", {
                      name: $t("ASSIGNMENT.DUE_TIME")
                    })
                  }}
                </b-form-invalid-feedback>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="12">
                <div role="group">
                  <label>{{ $t("GENERAL.DESCRIPTION") }}</label>
                  <editor
                    id="description"
                    name="description"
                    v-model="$v.form.description.$model"
                    apiKey="6i2ynvx6tmahwjbpz2k69fvox8ke77cq0ai0z4sasbh7jf0o"
                    :init="{
                      height: 400,
                      language: $i18n.locale !== 'en' ? 'fa' : 'en',
                      plugins: [
                        'fullscreen',
                        'link',
                        'paste',
                        'table',
                        'insertdatetime',
                        'wordcount',
                        'emoticons',
                        'directionality',
                        'visualblocks',
                        'visualchars',
                        'lists',
                        'advlist'
                      ],
                      toolbar_mode: 'sliding',
                      toolbar:
                        'undo redo ' +
                        '| bold italic underline strikethrough ' +
                        '| fontselect fontsizeselect formatselect ' +
                        '| alignleft aligncenter alignright alignjustify ' +
                        '| a11ycheck ltr rtl' +
                        '| outdent indent ' +
                        '|  numlist bullist checklist ' +
                        '| forecolor backcolor casechange permanentpen formatpainter removeformat ' +
                        '| charmap emoticons ' +
                        '| fullscreen  preview ' +
                        '| pageembed template link anchor '
                    }"
                  ></editor>
                  <b-form-invalid-feedback id="input-2-live-feedback">
                    {{
                      $t("VALIDATION.REQUIRED", {
                        name: $t("GENERAL.DESCRIPTION")
                      })
                    }}
                  </b-form-invalid-feedback>
                </div>
              </b-col>
            </b-row>
            <b-row>
              <b-col class="kt-margin-t-5">
                <b-button
                  type="submit"
                  id="kt_submit"
                  class="btn btn-primary btn-elevate kt-login__btn-primary"
                >
                  {{ $t("CLASS_MATERIALS_UPLOAD.UPLOAD") }}
                </b-button>
              </b-col>
            </b-row>
          </b-overlay>
        </b-form>
      </template>
    </KTPortlet>
  </div>
</template>

<script>
import ApiService from "@/common/api.service";
import Editor from "@tinymce/tinymce-vue";
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import KTPortlet from "@/views/partials/content/Portlet.vue";

import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

export default {
  name: "UploadNewAssignment.vue",
  mixins: [validationMixin],
  data() {
    return {
      errors: [],
      title: this.$t("GENERAL.UPLOAD_NEW_ASSIGNMENT"),
      form: {
        assignment_title: null,
        file: null,
        due_date: null,
        due_time: null,
        description: null
      },
      uploading: false,
      upload_percentage: 0
    };
  },
  components: {
    KTPortlet,
    editor: Editor
  },
  validations: {
    form: {
      assignment_title: {
        required
      },
      file: {
        required
      },
      due_date: {
        required
      },
      due_time: {
        required
      },
      description: {
        required
      }
    }
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    onSubmit() {
      this.errors = [];
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }
      const assignment_title = this.$v.form.assignment_title.$model;
      const due_date =
        this.$v.form.due_date.$model +
        " " +
        this.$v.form.due_time.$model +
        ":00";
      const description = this.$v.form.description.$model;
      let formData;
      let isFileUpload;
      let onUploading;
      isFileUpload = true;
      formData = new FormData();
      formData.append("id", this.$route.params.id);
      formData.append("assignment_title", assignment_title);
      formData.append("due_date", due_date);
      formData.append("file", this.$refs.file.value);
      formData.append("description", description);
      onUploading = function(progressEvent) {
        this.upload_percentage = Math.round(
          (progressEvent.loaded / progressEvent.total) * 100
        );
      }.bind(this);
      this.uploading = true;
      ApiService.post(
        "files/upload-assignment",
        formData,
        isFileUpload,
        onUploading
      )
        .then(() => {
          this.uploading = false;
          this.$router.push({ name: "uploadedAssignment" });
        })
        .catch(({ response }) => {
          this.uploading = false;
          this.errors = response.data.errors;
        });
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: this.$t("GENERAL.UPLOAD_NEW_ASSIGNMENT"),
        route: this.$route
      },
      { title: "" }
    ]);
    ApiService.post("lecturer/class-information", { id: this.$route.params.id })
      .then(({ data }) => {
        this.title +=
          " " +
          this.$t("UPLOADED_ASSIGNMENT.TITLE", {
            class: data.class_name,
            subject: data.subject,
            time: data.class_time,
            semester: data.semester,
            faculty: data.faculty
          });
      })
      .catch(({ response }) => {
        if (response && (response.status === 403 || response.status === 422)) {
          this.$router.push({ name: "lecturerAssignedClasses" });
        }
      });
  }
};
</script>
